<template>
  <div>
    <section class="review ">
      <b-row class="mt-10 mb-1 mb-lg-15 ml-5 mr-5 ml-lg-0 mr-lg-0">
        <b-col cols="12" sm="6" md="5" lg="4">
          <div class="imgdiv" fluid="true">
            <b-img
              src="image/img2-bg.png"
              alt="Image"
              class=""
              width="400"
              height="400"
              fluid
            >
            </b-img>
            
          </div>
        </b-col>
        <b-col cols="12" sm="6" md="7" lg="8">
          <div class="pl-lg-5 mb-0 ml-lg-7">
            <p class="lineheight font-size-25">About FeedView</p>
            <p class="pt-5 text-left font-size-14 text-justify">
              One of the best comprehensive research and review platforms,
              Feedview assists service providers and seekers along with software
              purchasers in selecting the finest agencies for their needs. It
              also helps IT firms to boost their SEO ranking and generate more
              leads by listing their companies FREE of cost and increase their
              revenue. FeedView is a community-focused website listing and
              reviews firm for IT companies and software solutions, as the name
              implies.
            </p>
            <p class="pt-5 text-left font-size-14 text-justify">
              FeedView is helping IT service providers and purchasers worldwide
              generate the leads and choose the best firm/software product that
              fulfils their individual requirements by providing a categorized
              directory, client evaluations, and company information &
              resources.
            </p>
            <!-- <b-link to="/getlisted">
              <b-button variant="dark" size="sm" class="border-radius-8"
                >Get Listed-It's Free
              </b-button></b-link
            > -->
          </div>
        </b-col>
      </b-row>
    </section>
    <section class="review">
      <b-row class="pt-0 pt-lg-10 mb-10 ml-5 mr-5 ml-lg-0 mr-lg-0">
        <b-col cols="12" sm="6" md="7" lg="8">
          <div>
            <p class="lineheight font-size-25">Why FeedView Only?</p>
            <p class="pt-5 text-left font-size-14 text-justify">
              FeedView is a NexGen business listing and review website that
              allows B2B service providers to increase visibility and showcase
              their brand credibility. Get <strong> FREE leads, boost your Google ranking
              with quality backlink, increase trust and credibility</strong> by accepting
              reviews from your valuable clients.
            </p>
            <p class="pt-5 text-left font-size-14 text-justify">
            <strong>Benefits to Register Your Business on FeedView and Get Valued Review</strong>
            </p><p class="font-size-14"> <ul>
             <li>Manually Verified Companies</li>
             <li>Get A FREE Leads</li>
             <li>Real Reviews from Company Representatives</li>
             <li>No paid promotions</li>
             <li>Free Advertising
</li>
             <li>Unique Review for each Category or Services</li>
             <li>Live backlink to your website</li>
             <li>Boost Your SEO</li>
             <li>Website Exposure</li>
             <li>Improve Trust and Credibility</li>
             <li>Get Industry Insights</li>
             <li>Competitive Analysis</li>
           </ul>
           
            </p>
            <!-- <b-link to="/getlisted"
              ><b-button variant="dark" size="sm" class="border-radius-8"
                >Get Listed-It's Free
              </b-button></b-link
            > -->
          </div>
        </b-col>
        <b-col cols="12" sm="6" md="5" lg="4"><div class="imgdiv">
            
            <b-img
              src="image/img-bg.png"
              alt="Image"
              class=""
              width="400"
              height="400"
              center
              fluid
            >
            </b-img>
            </div
        ></b-col>
      </b-row>
    </section>
    <section class="review">
      <b-row class="pb-15 mt-5">
        <div class="text-center">
          <span class="section-title font-size-20 ">Features</span>
          <h2 class="section-subtitle font-size-25">
            What makes us Brilliance
          </h2>
        </div>
        <b-col cols="12" sm="4">
          <b-card class="bg-none border-0 p-3 h-100">
            <div class="text-center">
              <div class="mb-2">
                <b-avatar
                  icon="award"
                  variant="secondary"
                  class="icon-color"
                  size="3rem"
                ></b-avatar>
              </div>
              <div class="mb-2 mt-5">
                <span class="fw-bold font-size-14"
                  >We are sited as a reliable source</span
                >
                <p class="mt-4 font-size-14">
                 Top rated Companies and software sellers <br />
                   trust our valuable research
                </p>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" sm="4">
          <b-card class="bg-none border-0 p-3 h-100">
            <div class="text-center">
              <div class="mb-2">
                <b-avatar
                  icon="people"
                  variant="secondary"
                  class="icon-color"
                  size="3rem"
                ></b-avatar>
              </div>
              <div class="mb-2 mt-5">
                <span class="fw-bold font-size-14"
                  >We deploy customer-centric approach</span
                >
                <p class="mt-4 font-size-14">
                  Our customer requirement is our priority and our 
                  research focuses on this
                </p>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" sm="4">
          <b-card class="bg-none border-0 p-3 h-100">
            <div class="text-center">
              <div class="mb-2">
                <b-avatar
                  icon="shield"
                  variant="secondary"
                  class="icon-color"
                  size="3rem"
                ></b-avatar>
              </div>
              <div class="mb-2 mt-5">
                <span class="fw-bold font-size-14"
                  >Our processes are 100% Safe & Secure</span
                >
                <p class="mt-4 font-size-14">
                  We treat our client's data as <br />
                   our important business resource
                </p>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>
<script>
export default {
  components: {},
  name: "aboutus",
};
</script>
